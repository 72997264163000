import Cookies from 'js-cookie';
import api from 'api';

export const Permissions = {
  CHANGE_PARTNER_ENVIROMENT: "alterar-parceiro"
}

export const hasPermission = (permission) => {
  const storedPermissions = Cookies.get('splitriskwl-backoffice-permissions');
  if (!storedPermissions) return false;
  const permissionsJson = JSON.parse(storedPermissions);
  return permissionsJson.some(({ feature, hasPermission }) => feature === permission && hasPermission);
}

export const fetchAndSetUserPermissions = async () => {
  const permissions = await api({
    method: 'POST',
    url: `/has-features`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: Cookies.get('splitriskwl-authtoken'),
    },
    data: {
      features: [
        'menu-propostas',
        'menu-apolices',
        'menu-reclamacoes',
        'menu-usuarios',
        'menu-corretores',
        'menu-sinistros',
        'menu-cancelamentos',
        'menu-configuracoes',
        'menu-financeiro',
        'menu-comissionamento-assessorias',
        'menu-produtos-corporativo',
        'exibir-comissao-cotacao',
        'alterar-equipes-corretor',
        'alterar-equipes-backoffice',
        'alterar-parceiro',
      ],
    },
    json: true,
  });

  Cookies.set('splitriskwl-backoffice-permissions', JSON.stringify(permissions?.data));
}