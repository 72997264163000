/* eslint-disable react-hooks/exhaustive-deps */
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import api from 'api';
import { useState, useEffect } from 'react';
import { ObjectVehicleNew, Button, Select } from 'components';
import { Container, SectionTitle } from '../../style';
import { NewFipeSearch } from './NewFipeSearch';
import { SwipeableDrawer } from '@mui/material';
import { Alert } from '@material-ui/lab';
import { ContainerMainInputs } from './styleVehicle';
import { primary } from 'styles/colorProvider';
import { toast } from 'react-toastify';
import { RiTaxiWifiFill } from 'react-icons/ri';

const VehicleModelStep = ({ quotationData, setQuotationData, nextStep, vehicleAccepted, setvehicleAccepted, verifyVehicle }) => {
  const [fipeSearchIsOpen, setFipeSearchIsOpen] = useState(false);
  const [vehicleData, setVehicleData] = useState(quotationData?.vehicle || {});
  const [vehiclesList, setVehiclesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [colorsList, setColorsList] = useState([]);
  const [vehicleUses, setVehicleUses] = useState([]);
  const [hasChange, setHasChange] = useState(false);
  const [under25, setUnder25] = useState(false);
  const [hasVehicleSelected, setHasVehicleSelected] = useState(false);

  const [fromCorrectData, setFromCorrectData] = useState(true);

  let selectedColor = colorsList.find((currentColor) => currentColor.id === Number(vehicleData?.id_color));

  /*
    Obtém as informações da FIPE com base em alguns dados do veículo.

    - Model (modelo) e brand (marca) são diferentes das salvas em banco.

    1º caso - Obtém as informações buscando de uma placa em que não puxa os veículos existentes mas já foi escolhido um previamente.

    2º caso- Ao voltar nas telas ele perde certas informações da FIPE que acontecem assim que escolhe algum veículo que apareceu na lista de veículos. Das informações salvas ele obtém as informações da FIPE que são necessárias.
  */
  const getFipeInfo = async (updateVehicleData = true) => {
    try {
      setLoading(true);

      const vehicleResp = await api({
        method: 'get',
        url: `/quotation/vehicle/${quotationData.quotationProposalData.vehicleId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });

      const vehicleInfo = vehicleResp?.data;

      if (!vehicleInfo?.id_vehicle_type || !vehicleInfo?.id_fuel || !vehicleInfo?.year_model || !vehicleInfo.id_vehicle_type)
        return setLoading(false);

      // vehicleInfo.id_vehicle_type

      // FIPE: Moto(7) Carro(1)
      const getVehicleType = vehicleInfo.id_vehicle_type === 4 ? 7 : 1;

      // Find fipe brands
      const respBrand = await api({
        method: 'GET',
        url: `/listBrands`,
        headers: {
          apiKey: 'a3607db1-530f-4565-93a1-7c2ff693c8db',
          apiSecret: 'f2b85508-8097-4adf-990a-6ba37b246fc1',
        },
        params: {
          type: getVehicleType,
        },
      });

      // Find fipe - vehicle brand
      const brands = respBrand?.data;
      if (!brands?.length) return setLoading(false);
      const brandFipe = brands.find((brand) => brand.Label === vehicleInfo.TB_CONSTANT_VEHICLE_BRANDS.name);
      if (!brandFipe?.Value) return setLoading(false);

      // Find fipe models
      const respModel = await api({
        method: 'POST',
        url: `/listModels`,
        headers: {
          apiKey: 'a3607db1-530f-4565-93a1-7c2ff693c8db',
          apiSecret: 'f2b85508-8097-4adf-990a-6ba37b246fc1',
        },
        data: {
          brand: brandFipe?.Value,
          type: getVehicleType,
        },
      });

      // Find fipe - vehicle model
      const models = respModel?.data;
      if (!models?.length) return setLoading(false);
      const modelFipe = models.find((model) => model.Label === vehicleInfo.TB_CONSTANT_VEHICLE_MODELS.name);
      if (!modelFipe?.Value) return setLoading(false);

      // Get fipe vehicle details
      const respFipe = await api({
        method: 'POST',
        url: `/getFipe`,
        headers: {
          apiKey: 'a3607db1-530f-4565-93a1-7c2ff693c8db',
          apiSecret: 'f2b85508-8097-4adf-990a-6ba37b246fc1',
        },
        data: {
          fuel: Number(vehicleInfo.id_fuel),
          yearModel: Number(vehicleInfo.year_model),
          model: Number(modelFipe?.Value),
          brand: Number(brandFipe?.Value),
          type: Number(getVehicleType),
        },
      });

      const fipeInfo = respFipe?.data;
      if (!fipeInfo) return setLoading(false);

      const fipeInfoVehicle = {
        ...fipeInfo,
        id_type: getVehicleType,
        type: getVehicleType,
        build_year: Number(vehicleInfo.manufacture_year),
      };

      // DA UM UPDATE NA VEHICLEDATA NO CASO DE VOLTAR NA PÁGINA COM UMA PLACA QUE NÃO LISTA VEÍCULOS E ESCOLHEU MANUALMENTE
      if (updateVehicleData) {
        setVehiclesList([fipeInfoVehicle]);

        setVehicleData({
          ...vehicleData,
          brand: fipeInfo?.brand,
          model: fipeInfo?.model,
          fipe_code: fipeInfo?.fipe_code,
          value: fipeInfo?.value,
          year: fipeInfo?.year,
          id_fuel: fipeInfo?.id_fuel,
          fuel: fipeInfo?.fuel,
          id_model: fipeInfoVehicle?.id_model,
          // id_vehicle_type: vehicleInfo?.id_vehicle_type,
          type: getVehicleType,
          id_type: getVehicleType,
          id_brand: fipeInfoVehicle?.id_brand,
        });

        setHasVehicleSelected(true);
      }

      setFromCorrectData(false);

      return {
        ...vehicleData,
        brand: fipeInfo?.brand,
        model: fipeInfo?.model,
        fipe_code: fipeInfo?.fipe_code,
        value: fipeInfo?.value,
        year: fipeInfo?.year,
        id_fuel: fipeInfo?.id_fuel,
        fuel: fipeInfo?.fuel,
        id_model: fipeInfoVehicle?.id_model,
        // id_vehicle_type: vehicleInfo?.id_vehicle_type,
        type: getVehicleType,
        id_type: getVehicleType,
        id_brand: fipeInfoVehicle?.id_brand,
      };
    } catch (error) {
      //
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const hasPlate = quotationData?.vehicle?.plate !== undefined;
      if (quotationData?.quotationProposalData) {
        setUnder25(quotationData.quotationProposalData?.under25);
      }

      selectedColor = colorsList.find((currentColor) => currentColor.id === Number(vehicleData?.id_color));

      const respPlate = await api({
        method: 'GET',
        url: hasPlate
          ? `/get-plate/${quotationData?.vehicle?.plate.replace(/[^a-zA-Z0-9]+/g, '')}`
          : `/get-chassi/${quotationData?.vehicle?.chassi.replace(/[^a-zA-Z0-9]+/g, '')}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        json: true,
      });

      setVehiclesList(respPlate.data);

      const vehicleId = quotationData?.quotationProposalData?.vehicleId;
      // If there is no vehicle find with this plate/chassi
      // And has vehicleId
      if (!respPlate.data?.length && vehicleId) {
        await getFipeInfo();
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);

      return toast.error('Erro obtendo as informações do veículo', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const loadColors = async () => {
    const response = await api.get(`/list-vehicle-colors`, {
      headers: {
        apiKey: 'a3607db1-530f-4565-93a1-7c2ff693c8db',
        apiSecret: 'f2b85508-8097-4adf-990a-6ba37b246fc1',
      },
    });
    setColorsList(response.data);
  };

  const loadUses = async () => {
    try {
      const resp = await api({
        method: 'GET',
        url: `/list-vehicle-uses`,
        headers: {
          apiKey: 'a3607db1-530f-4565-93a1-7c2ff693c8db',
          apiSecret: 'f2b85508-8097-4adf-990a-6ba37b246fc1',
        },
        params: {
          type: vehicleData?.type,
        },
      });
      setVehicleUses(resp?.data);
    } catch (error) {}
  };
  const handleSubmit = async () => {
    setLoadingRequest(true);
    try {
      // Check necessary data
      const vehicleId = quotationData.quotationProposalData.vehicleId;

      let vehicleFipeData = {};

      // Verifica se tem um vehicleID para serem feitas as lógicas
      if (vehicleId) {
        // Apenas não o model_year, não tem necessidade de olhar na FIPE
        if (!vehicleData?.model_year && vehicleData?.brand && vehicleData?.model) {
          const vehicleResp = await api({
            method: 'get',
            url: `/quotation/vehicle/${vehicleId}`,
            headers: {
              'Content-Type': 'application/json',
              Authorization: Cookies.get('splitriskwl-authtoken'),
            },
          });

          const { year_model } = vehicleResp.data;

          vehicleFipeData.year_model = year_model;
        } else {
          // Se não tem o model e o brand, obtem os da FIPE
          vehicleFipeData = await getFipeInfo(false);
        }
      }

      const newVehicle = await api({
        method: 'post',
        url: '/quotation/new-vehicle',
        data: {
          plate: vehicleData.plate,
          chassi: vehicleData.chassi,
          zero_km: vehicleData.zero_km,
          auction: vehicleData.auction,
          gnv: vehicleData.gnv,
          remarked: vehicleData.remarked,
          id_type: vehicleData?.id_type || vehicleFipeData?.id_type,
          vehicle_type: vehicleData.vehicle_type,
          id_brand: Number(vehicleData.id_brand) || vehicleFipeData?.id_brand,
          brand: vehicleData.brand || vehicleFipeData?.brand,
          id_model: vehicleData.id_model || vehicleFipeData?.id_model,
          model: vehicleData.model || vehicleFipeData?.model,
          model_year: vehicleData.year || vehicleFipeData?.year_model,
          build_year: vehicleData.build_year,
          value: vehicleData.value,
          id_fuel: vehicleData.id_fuel,
          fuel: vehicleData.fuel,
          fipe_code: vehicleData.fipe_code,
          id_color: 1,
          id_vehicle_use: vehicleData.id_vehicle_use,
          renavam: vehicleData.renavam,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });

      await api({
        method: 'patch',
        url: `/quotation/vehicle/${quotationData.quotationProposalData.quotationProposalId}`,
        data: {
          id_vehicle: newVehicle.data.id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
      });

      setQuotationData((prev) => ({
        ...prev,
        vehicle: { ...prev.vehicle, under25, type_vehicle: vehicleData.id_type, ...vehicleData },
      }));

      setQuotationData({
        ...quotationData,
        vehicle: {
          ...newVehicle.data,
          id_color: null,
          some_driver_18_25_years_old: null,
          vehicle_already_insured: null,
          build_year: newVehicle.data.manufacture_year,
          model: newVehicle.data.model,
          // from_auction: newVehicle.data.from_auction === 0 ? false : true,
        },
        quotationProposalData: {
          ...quotationData.quotationProposalData,
          vehicleId: newVehicle.data.id,
          under25: null,
        },
      });
      nextStep();
    } catch (error) {
      setLoadingRequest(false);
      const title = error?.response?.data?.error || 'Erro ao criar veiculo';
      return toast.error(title, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
    setLoadingRequest(false);
  };

  const onClose = () => {
    setFipeSearchIsOpen(false);
  };

  const onFinish = (data) => {
    onClose();

    setVehiclesList([data]);

    setVehicleData((prev) => ({ ...prev, ...data }));
    // verifyVehicle((prev) => ({ ...prev, ...data }));
    setHasChange(true);
    // setQuotationData({ ...quotationData, vehicle: { ...quotationData?.vehicle, ...data } });
  };

  const handleVehicleChange = (data) => {
    setVehicleData((prev) => ({ ...prev, ...data }));
    setHasChange(true);
    // setQuotationData({ ...quotationData, vehicle: { ...quotationData?.vehicle, ...data } });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadColors();
  }, []);

  useEffect(() => {
    loadUses();
  }, []);

  // CHECA SE HOUVE UMA MUDANÇA PARA ALTERAR O STATE DE SELEÇÃO DO VEÍCULO
  // CASO: SELECIONAR UM VEÍCULO DA LISTAGEM DA PLACA
  useEffect(() => {
    if (vehiclesList?.length) {
      const hasVehicleSelected = vehiclesList?.find((item) => {
        return vehicleData?.id_model === item?.id_model && vehicleData?.value === item?.value;
      });

      if (!hasVehicleSelected) return;
      setHasVehicleSelected(true);
    }
  }, [vehicleData]);

  // CHECA SE EXISTE UMA LISTA DE VEÍCULOS
  // FORÇA A SELEÇÃO DE UM VEÍCULO CASO BATA AS INFORMAÇÕES DE MODELO E VALUE
  useEffect(() => {
    if (vehiclesList?.length) {
      const hasVehicleSelected = vehiclesList?.find((item) => {
        return vehicleData?.id_model === item?.id_model && vehicleData?.value === item?.value;
      });

      if (!hasVehicleSelected) return;
      setVehicleData((prev) => ({ ...prev, ...hasVehicleSelected }));
      setHasVehicleSelected(true);
    }
  }, [vehiclesList]);

  return (
    <Container>
      <SectionTitle>Selecione um modelo</SectionTitle>

      {loading && (
        <div className="loading-vehicle">
          <ReactLoading color={primary} height={25} width={25} type="spin" />
          <h3>Por favor, aguarde...</h3>
          <p>Buscando dados do veículo.</p>
        </div>
      )}

      {vehiclesList?.map((item, index) => (
        <ObjectVehicleNew
          key={index}
          active={vehicleData?.id_model === item?.id_model && vehicleData?.value === item?.value}
          data={item}
          onClick={() => handleVehicleChange(item)}
        />
      ))}

      {!loading && vehiclesList.length < 1 && (
        <Alert style={{ marginTop: 20 }} severity={'warning'}>
          Nenhum veículo encontrado! Para continuar, busque o veículo na tabela Fipe.
        </Alert>
      )}

      {(vehiclesList.length < 1 || !fromCorrectData) && !loading && (
        <p
          onClick={() => setFipeSearchIsOpen(true)}
          style={{ fontSize: 11, marginBottom: 20, textDecoration: 'underline', cursor: 'pointer' }}
        >
          {vehicleData?.value ? 'Selecionar novo modelo' : 'Buscar na tabela Fipe'}
        </p>
      )}

      <SwipeableDrawer anchor={'bottom'} open={fipeSearchIsOpen} onClose={onClose}>
        <NewFipeSearch onFinish={onFinish} />
      </SwipeableDrawer>

      <ContainerMainInputs>
        <div>
          {/* VEHICLE_USE */}
          <Select
            label="Uso do veículo"
            height="35px"
            width="360px"
            style={{ marginBottom: 25 }}
            placeholder="Selecione o uso do veículo"
            value={vehicleData?.id_vehicle_use}
            icon={<RiTaxiWifiFill />}
            onChange={(e) => {
              setVehicleData((prev) => ({
                ...prev,
                id_vehicle_use: Number(e.target.value),
                vehicle_use: vehicleUses[vehicleUses?.findIndex((i) => i.id === Number(e.target.value))],
              }));
              setHasChange(true);
            }}
            onload={(event) => event?.target?.value}
          >
            <option value="">Selecione</option>
            {vehicleUses.map((item, index) => (
              <option value={item.id} key={index}>
                {item.name}
              </option>
            ))}
          </Select>

          {/* REMARKED */}
          <div
            className="checkbox-item"
            style={{ marginTop: 20, marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}
          >
            <input
              // disabled={loadingAuction}
              type="checkbox"
              id="remarked"
              name="remarked"
              checked={vehicleData.remarked}
              disabled={vehicleData.auction}
              onChange={(e) => {
                setVehicleData((prev) => ({ ...prev, remarked: !vehicleData.remarked }));
                setHasChange(true);
              }}
            />
            <label for="remarked" style={{ cursor: 'pointer' }}>
              <p style={{ margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                Chassi remarcado/ Veículo recuperado de sinistro &nbsp;
              </p>
              <small style={{ marginLeft: 25, color: '#808080', margin: 0 }}>
                Marque esta opção se o veículo possuir chassi remarcado ou se tratar de um recuperado de sinistro
              </small>
            </label>
          </div>

          {/* 0KM */}
          {/* <div
            className="checkbox-item"
            style={{ marginTop: 20, marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}
          >
            <input
              type="checkbox"
              id="zeroKm"
              name="zeroKm"
              checked={vehicleData?.zero_km}
              onChange={(e) => {
                setVehicleData((prev) => ({ ...prev, zero_km: !vehicleData.zero_km }));
                setHasChange(true);
              }}
            />
            <label for="zeroKm" style={{ cursor: 'pointer' }}>
              <p style={{ margin: 0 }}>Veículo 0km (concessionária)</p>
              <small style={{ marginLeft: 25, color: '#808080', margin: 0 }}>
                Marque esta opção se o veículo for 0km e ainda estiver na concessionária.
              </small>
            </label>
          </div> */}

          {/* GNV */}
          <div
            className="checkbox-item"
            style={{ marginTop: 30, marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}
          >
            <input
              type="checkbox"
              id="gnv"
              name="gnv"
              checked={vehicleData?.gnv}
              onChange={() => {
                setVehicleData((prev) => ({ ...prev, gnv: !vehicleData?.gnv }));
                setHasChange(true);
              }}
            />
            <label for="gnv" style={{ cursor: 'pointer' }}>
              <p style={{ margin: 0 }}>Veículo movido a Gás Natural Veicular (GNV)</p>
              <small style={{ marginLeft: 25, color: '#808080', margin: 0 }}>
                Marque esta opção se o veículo for movido a GNV.
              </small>
            </label>
          </div>
        </div>
      </ContainerMainInputs>

      {/* {vehicleData?.model && !vehicleAccepted && (
        <Alert style={{ marginTop: 20 }} severity="error">
          Oops! Infelizmente não oferecemos cobertura para este veículo!
        </Alert>
      )} */}

      <Button
        disabled={!vehicleData?.id_vehicle_use || loadingRequest || loading || !hasVehicleSelected}
        buttonColor="#fe9501"
        buttonBoxShadowColor="#fe9501"
        style={{ marginLeft: 0 }}
        height="35px"
        onClick={() => {
          hasChange ? handleSubmit() : nextStep();
        }}
      >
        {!loadingRequest && 'Continuar'}
        {loadingRequest && <ReactLoading color={primary} height={24} width={24} type="spin" />}
      </Button>
    </Container>
  );
};

export default VehicleModelStep;
