/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import api from 'api';
import { Alert } from '@material-ui/lab';
import { Header, Input, Button, Select, ConfirmDialog } from 'components';
import { Container, ContentArea, Title, LoadingArea, Subtitle, Form, FormPermissions, SelectLine, ButtonLine } from './styles';
import { success, white } from 'styles/colorProvider';
import { useHistory } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import { formatRoleCategory } from 'utils/userCategory';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { formatCpfCnpjValue } from 'utils/mask';
import { fetchAndSetUserPermissions } from 'utils/permissions';

function UserData({ location }) {
  const history = useHistory();
  const loggedUser = jwtDecode(Cookies.get('splitriskwl-authtoken'));
  const userId = parseInt(location.pathname.split('/detalhes-do-usuario/')[1]);
  const isCurrentUser = userId === loggedUser?.id;

  const [loading, setLoading] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingUpdatePermissions, setLoadingUpdatePermissions] = useState(false);
  const [userData, setUserData] = useState({});
  const [featureData, setFeatureData] = useState();
  const [rolesData, setRolesData] = useState([]);
  const [selectedAccesGroup, setSelectedAccessGroup] = useState();
  const [appliedAccessGroup, setAppliedAccessGroup] = useState();
  const [modal, setModal] = useState(false);
  const [modalBlock, setModalBlock] = useState(false);
  const currentAccessGroup = rolesData.find((i) => i.id === userData.id_access_group);
  const [isAdmin, setIsAdmin] = useState(loggedUser?.role === 'admin' || currentAccessGroup?.role === 'admin');

  const handleRecoveryPassword = async () => {
    setLoadingReset(true);
    await api({
      method: 'GET',
      url: `/reset-password-backoffice/${userId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    });
    toast.success('Senha resetada com sucesso! A nova senha são os 6 últimos números do CPF ou CNPJ cadastrado.', {
      position: toast.POSITION.TOP_RIGHT,
      theme: 'colored',
    });
    setModal(false);
    setLoadingReset(false);
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await api({
        method: 'GET',
        url: `/user/${userId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          backoffice: true,
        },
        json: true,
      });

      const features = await api({
        method: 'GET',
        url: `/list-features`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        json: true,
      });

      const roles = await api({
        method: 'GET',
        url: `/access-groups`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        json: true,
      });

      setFeatureData(features.data);
      setRolesData(roles.data.response);
      setUserData({ ...response.data, birthdate: moment(response.data.birthdate).format('DD/MM/YYYY') });
      setSelectedAccessGroup(response.data.id_access_group);
      setAppliedAccessGroup(response.data.id_access_group);
      setLoading(false);
    } catch (error) {
      history.push('/nao-autorizado');
    }
  };

  const getMyRole = async () => {
    try {
      const response = await api({
        method: 'GET',
        url: '/me',
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        json: true,
      });
      setIsAdmin(response.data.role === 'admin');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isAdmin) {
      getMyRole();
    }
    loadData();
  }, []);

  const handleChangeFeature = (feature) => {
    const isChecked = userData?.features?.findIndex((i) => i === feature) > -1;
    const curretFeaturesList = [...userData?.features];

    if (isChecked) {
      curretFeaturesList.splice(
        curretFeaturesList.findIndex((listItem) => listItem === feature),
        1,
      );
      setUserData({ ...userData, features: curretFeaturesList });
    } else {
      curretFeaturesList.push(feature);
      setUserData({ ...userData, features: curretFeaturesList });
    }
  };

  const handleApplyAccessGroup = (group) => {
    const accessGroup = rolesData.filter((i) => i.id === parseInt(group))[0];
    const features = accessGroup.id_features.split(',');
    const curretFeaturesList = features.map((i) => parseInt(i));
    setUserData({ ...userData, features: curretFeaturesList });
    setAppliedAccessGroup(parseInt(group));
  };

  const handleUpdateUser = async () => {
    setLoadingUpdate(true);
    try {
      await api({
        method: 'PATCH',
        url: `/user/${userId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          backoffice: true,
        },
        data: {
          ...userData,
          cpf: userData.cpf.replace(/\D/g, ''),
          phone: userData.phone.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', ''),
          birthdate: moment(userData.birthdate, 'DD/MM/YYYY'),
          gender: parseInt(userData.gender),
        },
        json: true,
      });
      setLoadingUpdate(false);
      toast.success('Usuário atualizado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      setLoadingUpdate(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleUpdateUserPermissions = async () => {
    setLoadingUpdatePermissions(true);
    try {
      await api({
        method: 'PATCH',
        url: `/user-permissions/${userId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          accessGroup: appliedAccessGroup,
          features: userData.features,
        },
        json: true,
      });
      setLoadingUpdatePermissions(false);
      toast.success('Permissões alteradas com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      if (isCurrentUser) {
        await fetchAndSetUserPermissions();
      }
      loadData();
    } catch (error) {
      setLoadingUpdatePermissions(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleBlockUnblockUser = async () => {
    setLoadingUpdate(true);
    try {
      await api({
        method: 'PATCH',
        url: `/user/${userId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          backoffice: true,
        },
        data: {
          active: userData?.active ? 0 : 1,
        },
        json: true,
      });
      setLoadingUpdate(false);
      setModalBlock(false);
      toast.success('Usuário atualizado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      loadData();
    } catch (error) {
      setLoadingUpdate(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const categories = [...new Set(featureData?.map((feature) => feature.category))].filter((i) => i !== 'user').sort();

  const renderConfirmDialogArea = () => (
    <ConfirmDialog
      open={modal}
      onCancel={() => setModal(false)}
      content="Tem certeza de que deseja resetar a senha deste usuário? A nova senha serão os 6 últimos números do CPF cadastrado."
      title="Resetar senha"
      onConfirm={handleRecoveryPassword}
      loading={loadingReset}
    />
  );

  const renderConfirmDialogAreaBlock = () => (
    <ConfirmDialog
      open={modalBlock}
      onCancel={() => setModalBlock(false)}
      content={
        userData?.active
          ? 'Tem certeza de que deseja bloquear este usuário? Ele não será mais capaz de acessar o sistema.'
          : 'Tem certeza de que deseja desbloquear este usuário? Ele poderá voltar a acessar o sistema.'
      }
      title={userData?.active ? 'Bloquear usuário' : 'Desbloquear usuário'}
      onConfirm={handleBlockUnblockUser}
      loading={loadingUpdate}
    />
  );

  return (
    <>
      {renderConfirmDialogArea()}
      {renderConfirmDialogAreaBlock()}
      <Header />
      <Container>
        {loading && (
          <LoadingArea>
            <ReactLoading color={success} height={40} width={40} type="spin" />
          </LoadingArea>
        )}
        {!loading && (
          <ContentArea>
            <Title>
              <IconButton size="small" style={{ marginRight: 15 }} onClick={() => history.push('/backoffice/usuarios')}>
                <MdChevronLeft size={24} />
              </IconButton>
              DETALHES DO USUÁRIO
            </Title>

            {!userData?.active && (
              <Alert style={{ marginBottom: 20 }} severity="error">
                Este usuário está bloqueado e não possui acesso ao sistema!
              </Alert>
            )}

            <Subtitle>Dados pessoais</Subtitle>

            <Divider />

            <Form>
              <div class="grid-item">
                <Input
                  height="35px"
                  label="Nome"
                  placeholder="Nome"
                  value={userData?.name}
                  onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                />
              </div>

              <div class="grid-item">
                <Input
                  height="35px"
                  label="E-mail"
                  placeholder="E-mail"
                  value={userData?.email}
                  onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                />
              </div>

              <div class="grid-item">
                <Input
                  height="35px"
                  mask="(99) 99999-9999"
                  label="Telefone"
                  placeholder="Telefone"
                  value={userData?.phone}
                  onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
                />
              </div>

              <div class="grid-item">
                <Input
                  height="35px"
                  label="CPF / CNPJ"
                  placeholder="CPF / CNPJ"
                  value={formatCpfCnpjValue(userData?.cpf)}
                  onChange={(e) => {
                    if (e.target.value?.replace(/\D/g, '')?.length <= 14) setUserData({ ...userData, cpf: e.target.value });
                  }}
                />
              </div>

              <div class="grid-item">
                <Input
                  height="35px"
                  mask="99/99/9999"
                  label="Data de nascimento"
                  placeholder="Data de nascimento"
                  value={userData?.birthdate}
                  onChange={(e) => setUserData({ ...userData, birthdate: e.target.value })}
                />
              </div>

              <div class="grid-item">
                <Select
                  label="Sexo"
                  height="35px"
                  value={userData?.gender}
                  onChange={(e) => setUserData({ ...userData, gender: e.target.value })}
                >
                  <option value={1}>Masculino</option>
                  <option value={2}>Feminino</option>
                </Select>
              </div>
            </Form>

            <ButtonLine>
              <Button height="35px" outlined disabled={loading} onClick={() => setModalBlock(true)}>
                {userData?.active ? 'Bloquear usuário' : 'Desbloquear usuário'}
              </Button>

              <Button height="35px" outlined disabled={loading} onClick={() => setModal(true)}>
                Resetar senha
              </Button>

              <Button height="35px" disabled={loadingUpdate} onClick={handleUpdateUser}>
                {loadingUpdate ? <ReactLoading color={white} height={24} width={24} type="spin" /> : 'Alterar informações'}
              </Button>
            </ButtonLine>

            {isAdmin && (
              <>
                <Subtitle>Permissões</Subtitle>
                <Divider />

                <SelectLine>
                  <Select
                    label="Aplicar grupo de acesso"
                    height="30px"
                    value={selectedAccesGroup}
                    onChange={(e) => setSelectedAccessGroup(parseInt(e.target.value))}
                  >
                    {!currentAccessGroup && <option value="">Selecione</option>}
                    {rolesData?.map((role) => (
                      <option value={role.id}>{role.name}</option>
                    ))}
                  </Select>
                  <Button
                    disabled={!selectedAccesGroup || selectedAccesGroup === '' || selectedAccesGroup === appliedAccessGroup}
                    height="30px"
                    style={{ margin: 0, marginBottom: 3, marginLeft: 10, marginTop: 20 }}
                    onClick={() => handleApplyAccessGroup(selectedAccesGroup)}
                  >
                    Aplicar
                  </Button>
                </SelectLine>

                <FormPermissions style={{ paddingTop: 10 }}>
                  {categories
                    ?.filter((cat) => cat !== 'public-api' && cat !== 'splitrisk')
                    ?.map((category) => (
                      <div class="permission-section">
                        <h3>{formatRoleCategory(category)}</h3>
                        <div className="items-list">
                          {featureData
                            ?.filter((f) => f.category === category)
                            ?.map((feature) => (
                              <div className="checkbox-item">
                                <input
                                  type="checkbox"
                                  id={feature?.id}
                                  name={feature?.id}
                                  checked={userData?.features?.findIndex((i) => i === feature.id) > -1}
                                  onChange={() => handleChangeFeature(feature?.id)}
                                />
                                <label for={feature?.id}>{feature?.name}</label>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </FormPermissions>
                <ButtonLine style={{ marginTop: 20 }}>
                  <Button
                    outlined
                    height="35px"
                    disabled={loadingUpdate}
                    style={{ margin: 0, marginBottom: 20, marginRight: 10 }}
                    onClick={() => setUserData({ ...userData, features: [] })}
                  >
                    Limpar todas
                  </Button>
                  <Button
                    height="35px"
                    disabled={loadingUpdatePermissions}
                    style={{ margin: 0, marginBottom: 20 }}
                    onClick={handleUpdateUserPermissions}
                  >
                    {loadingUpdatePermissions ? (
                      <ReactLoading color={white} height={24} width={24} type="spin" />
                    ) : (
                      'Alterar permissões'
                    )}
                  </Button>
                </ButtonLine>
              </>
            )}
          </ContentArea>
        )}
      </Container>
    </>
  );
}

export default UserData;
