import styled, { createGlobalStyle } from 'styled-components';
import { white, darkGray, middleGray, middleGraySecondary } from 'styles/colorProvider';

export const PrintStyles = createGlobalStyle`
  @media print {
    body {
      margin: 0;
      padding: 0;
      margin-bottom: 60px;
    }
    footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: #f1f1f1;
      padding: 10px;
    }
    .page-break {
      page-break-before: always;
    }
  }
`;

export const LogoIcon = styled.div`
  display: flex;
  width: 100px;
  height: 40px;
`;

export const Row = styled.div`
  gap: 6px;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
`;

export const RowLabel = styled.b`
  font-size: 16px;
`;

export const RowText = styled.p`
  font-size: 16px;
`;

export const FooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 40px;
`;

export const FooterText = styled.span`
  width: 80%;
  font-size: 14px;
`;

export const Footer = styled.footer`
  display: none;
  @media print {
    display: flex;
    border-top: 1px solid black;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 20px 0 30px 0;
`;

export const HrHeader = styled.div`
  width: 100%;
  border: 1px solid ${middleGraySecondary};
  margin-bottom: 30px;
`;

export const SectionContainer = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  margin: 10px 0;
  background: #d9d9d9;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;

  @media print {
    height: 20px;
  }
`;

export const SectionTitle = styled.h1`
  color: black;
  font-size: 100px;
  font-weight: 800;
`;

export const SectionSmall = styled.small`
  font-size: 14px;
  font-weight: 300;
`;

export const ContainerBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const RowBetween = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({ width }) => width || 'auto'};
  gap: ${({ gap }) => gap || '0'};
`;

export const ColumnBetween = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || 'auto'};
  gap: ${({ gap }) => gap || '0'};
`;

export const Container = styled.div`
  display: flex;
  gap: 20px;
  background-color: ${white};
  width: 100%;
  margin-bottom: 100px;
  box-sizing: border-box;
  justify-content: center;

  header {
    padding: 5px 30px;
    box-sizing: border-box;
  }

  /* section {
    flex: 1 1 100%;
    box-sizing: border-box;

    div {
      color: rgb(234 147 16);
      font-size: 28px;
    }

    .responsible {
      font-size: 12px;
    }
  } */

  h1 {
    color: ${middleGraySecondary};
    font-size: 20px;
    margin: 0px;
    margin-bottom: 20px;
  }

  h2 {
    /* color: ${darkGray}; */
    font-size: 16px;
    margin: 0px;
    margin-bottom: 30px;
    font-weight: 500;
  }
  /* 
  p {
    color: ${middleGray};
    margin: 10px 0px;
    font-size: 14px;
    font-weight: normal;
  } */

  strong {
    color: ${middleGraySecondary};
    margin: 10px 0px;
    font-size: 14px;
    font-weight: bold;
  }

  span {
    color: ${darkGray};
  }

  table {
    margin-bottom: 20px;
  }

  td {
    font-size: 13px;
  }

  tr {
    font-size: 20px;
  }

  thead {
    color: ${middleGraySecondary} !important;
    font-weight: bold;
  }

  hr {
    border: none;
    border-top: 1px solid ${middleGray};
    height: 3px;
  }

  .printBrake {
    @media print {
      margin-top: 100px;
      margin-bottom: 30px;
    }
  }

  .logo {
    max-width: 300px;
    height: auto;
    align-self: center;
  }
  .boxTitulo {
    width: 100%;
  }
  .name {
    font-weight: 800;
  }

  .descriptionheader {
    font-size: 11px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Line = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  gap: 40px;
  width: 900px;
  max-width: 90%;
  margin: 0 auto;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const LoadingArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
`;

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @media print {
    display: none;
  }
  Button {
    background-color: ${middleGraySecondary};
  }
`;

// _______________________________________________________
export const WrapRow = styled.hr`
  flex: ${({ flex }) => flex || 'auto'};
  width: ${({ size }) => size || '900px'};
  height: ${({ height }) => height || '1px'};
  background: ${({ color }) => color || middleGraySecondary};
  margin: 0px auto;
  border: 0 !important;
  opacity: ${({ opacity }) => opacity || '0.4'};
`;

export const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: ${({ gap }) => gap || '20px 40px'};
  width: 900px;
  max-width: 90%;
  padding: ${({ padding }) => padding || '20px 0'};
  margin: 0 auto;
`;

export const ColumnContainer = styled.div`
  flex: ${({ flex }) => flex || '1 1'};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: ${({ gap }) => gap || '10px 20px'};
  box-sizing: border-box;
  background: ${({ background }) => background || 'none'};
  h1 {
    margin: 0;
    line-height: 20px;
  }
`;

export const BlockText = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ gap }) => gap || '0'};
  box-sizing: border-box;
`;

export const Text = styled.p`
  flex: ${({ flex }) => flex || '1 1 100%'};
  margin: 0;
  color: ${({ color }) => color || middleGraySecondary};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  font-weight: 500;
`;

export const HightLightText = styled(Text)`
  flex: ${({ flex }) => flex || '0 1 300px'};
  background: ${middleGraySecondary};
  color: white;
  padding: 3px 5px;
`;

export const Printable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${middleGraySecondary};

  @media print {
    width: 100%;
  }

  .logoArea {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      height: 50px;
    }
  }

  .titleArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h1 {
      margin: 0px;
      margin-bottom: 5px;
      font-size: 22px;
    }
    h2 {
      margin: 0px;
    }
  }

  section {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    // border-bottom: 3px solid ${middleGraySecondary};
    // @media print {
    //   border-bottom: 3px solid black;
    // }

    h1 {
      margin: 0px;
      margin-bottom: 10px;
      font-size: 22px;

      @media (max-width: 900px) {
        font-size: 15px;
      }
    }
  }

  h4 {
    margin: 0px;
    font-weight: normal;

    @media (max-width: 900px) {
      font-size: 12px;
    }
  }

  @media print {
    b, p, small {
      font-size: 12px;
    }
  }

  b {
    margin: 0px;
  }

  p {
    margin: 0px;
    font-weight: normal;
  }

  .columns {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .leftArea {
    width: 50%;
    margin-right: 50px;
  }

  .rightArea {
    width: 50%;
  }

  @media (max-width: 1000px) {
    .columns {
      flex-direction: column;
    }
    .leftArea,
    .rightArea {
      width: 100%;
      margin-right: 0;
    }
  }

  @media print {
    .columns {
      flex-direction: row;
    }
    .leftArea {
      width: 50%;
      margin-right: 50px;
    }
    .rightArea {
      width: 50%;
    }
  }

  .highlight {
    background-color: ${middleGraySecondary} !important;
    color: #fff;
    width: 330px;
    padding: 1px 2px;

    @media print {
      background-color: #000 !important;
      width: 300px;
    }
  }

  @media (max-width: 500px) {
    .highlight {
      width: 200px;
      font-size: 10px;
    }
  }

  .dateInfo {
    color: ${middleGraySecondary};
    font-size: 15px;
  }

  .smallInfo {
    font-size: 8px;
    margin: 0px;
  }

  .insuredData {
    font-weight: bold;
  }

  .valueInfo {
    width: 100%;
  }

  .generalConditionsTitle {
    margin-bottom: 10px;
    margin: 10px 0px;
    color: ${middleGraySecondary};
    font-weight: bold;
    font-size: 14px;
  }

  }
`;
