/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ChangePassword from 'components/ChangePassword';
import Cookies from 'js-cookie';
import Avatar from '@mui/material/Avatar';
import api from 'api';

import { GoChevronDown } from 'react-icons/go';
import { AiOutlineLogout, AiOutlineUser } from 'react-icons/ai';
import { IoWalletOutline } from 'react-icons/io5';
import { VscVmActive, VscInfo } from 'react-icons/vsc';
import { CgLock } from 'react-icons/cg';
import { BsBagCheck } from 'react-icons/bs';
import { Container, ContentArea, Logo, ContainerMenu } from './styles';
import { useHistory } from 'react-router-dom';
import { secondary, white } from 'styles/colorProvider';
import { Dialog } from 'components';
import { useStoreActions, useStoreState } from 'easy-peasy';

import appVersion from '../../settings.json';
import { fetchAndSetUserPermissions } from 'utils/permissions';

function Header() {
  const history = useHistory();

  const host = window.location.hostname;
  const isOceanica = host.split('.')[0] === 'oceanica';

  const [userData, setUserData] = useState({});
  const [showInfo, setShowInfo] = useState(false);

  const getUser = async () => {
    const userInfo = {
      method: 'GET',
      url: `/me`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    };
    const response = await api(userInfo);

    setUserData(response.data);
  };

  useEffect(() => getUser(), []);

  const [brokerPermission, setBrokerPermission] = [
    useStoreState(({ brokerPermission }) => brokerPermission.brokerPermission),
    useStoreActions(({ brokerPermission }) => brokerPermission.setBrokerPermission),
  ];

  const [backofficePermission, setBackofficePermission] = [
    useStoreState(({ backofficePermission }) => backofficePermission.backofficePermission),
    useStoreActions(({ backofficePermission }) => backofficePermission.setBackofficePermission),
  ];

  const handleInitial = async (e) => {
    e.preventDefault();
    history.push('/minhas-apolices');
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePassword = () => {
    handleOpen();
  };

  const handleLogout = async () => {
    const auth = Cookies.get('splitriskwl-authtoken');
    Cookies.remove('splitriskwl-authtoken');
    Cookies.remove('splitriskwl-acceptCookies');
    handleOpen();
    history.push('/');

    await api({
      method: 'POST',
      url: `/logout`,
      headers: {
        'Content-Type': 'application/json',
        authorization: auth,
      },
      data: {},
      json: true,
    });
  };

  const getPermission = async () => {
    const getBrokerPermission = await api({
      method: 'GET',
      url: `/has-feature`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
        feature: 'broker-area',
      },
      json: true,
    });
    setBrokerPermission(getBrokerPermission?.data);

    const getBackofficePermission = await api({
      method: 'GET',
      url: `/has-feature`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
        feature: 'backoffice-area',
      },
      json: true,
    });
    setBackofficePermission(getBackofficePermission?.data);

    await fetchAndSetUserPermissions();
  };

  const handleOpen = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  useEffect(() => {
    handleClose();
  }, [open]);

  useEffect(() => !brokerPermission && getPermission(), [brokerPermission]);

  return (
    <>
      <Container>
        <ContentArea>
          <Logo onClick={handleInitial} />
          <ContainerMenu onClick={handleClick}>
            <Avatar
              style={{ marginRight: 5 }}
              sx={{ width: 30, height: 30, fontSize: 14, background: secondary }}
              src={userData?.signedUrl}
              image={userData?.signedUrl}
            />
            <GoChevronDown color={white} size={14} />
          </ContainerMenu>
        </ContentArea>
      </Container>
      <Menu
        style={{ marginTop: '50px', marginLeft: '-40px' }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {backofficePermission && (
          <MenuItem onClick={() => history.push('/backoffice/propostas')} dense>
            <VscVmActive size={18} style={{ marginRight: 10 }} />
            Portal backoffice
          </MenuItem>
        )}
        {brokerPermission && isOceanica && (
          <MenuItem onClick={() => history.push('/comercial/minhas-cotacoes')} divider dense>
            <BsBagCheck size={17} style={{ marginRight: 11 }} />
            Área do corretor
          </MenuItem>
        )}
        {brokerPermission && !isOceanica && (
          <MenuItem onClick={() => history.push('/comercial/minhas-cotacoes')} divider dense>
            <BsBagCheck size={17} style={{ marginRight: 11 }} />
            Portal comercial
          </MenuItem>
        )}

        <MenuItem onClick={() => history.push('/meu-perfil')} dense>
          <AiOutlineUser style={{ marginRight: 10 }} />
          Meu perfil
        </MenuItem>

        {/* {!location.pathname.includes('/novo-sinistro') && (
          <MenuItem onClick={() => history.push('/novo-sinistro')} dense>
            <BsShieldExclamation style={{ marginRight: 10 }} />
            Comunicar sinistro
          </MenuItem>
        )} */}

        <MenuItem onClick={() => history.push('/minha-carteira')} dense>
          <IoWalletOutline style={{ marginRight: 10 }} />
          Minha carteira
        </MenuItem>

        <MenuItem onClick={handleChangePassword} dense>
          <CgLock style={{ marginRight: 10 }} />
          Alterar senha
        </MenuItem>

        <MenuItem onClick={() => setShowInfo(true)} dense>
          <VscInfo size={16} style={{ marginRight: 10 }} />
          Sobre
        </MenuItem>

        <MenuItem onClick={handleLogout} dense>
          <AiOutlineLogout style={{ marginRight: 10 }} />
          Sair
        </MenuItem>
      </Menu>

      <Dialog open={open} onClose={handleCloseModal} title="Alterar senha">
        <ChangePassword handleCloseModal={handleCloseModal} />
      </Dialog>

      <Dialog open={showInfo} onClose={() => setShowInfo(false)} title="Sobre o portal">
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <p style={{ margin: 0, fontWeight: 'bold' }}>Portal Corporate Split Risk</p>
          <p style={{ margin: 0, fontSize: 14 }}>Versão: {appVersion?.version}</p>
        </div>
      </Dialog>
    </>
  );
}

export default Header;
