import React from 'react';
import moment from 'moment';
import { Container, Div, Title, Subtitle } from './styles';
import { useHistory } from 'react-router-dom';
import { TbReportMoney } from 'react-icons/tb';
import { BsPrinter } from 'react-icons/bs';
import { BiMessageSquareEdit } from 'react-icons/bi';
import { success, blue, error } from 'styles/colorProvider';
import { formatPlate, formatCurrency } from 'react-data-formatter';
import { Tooltip, IconButton } from '@material-ui/core';

function QuotationItem({ quotation, actionsActive }) {
  const history = useHistory();

  const navigate = async (e) => {
    e.preventDefault();
    history.push({
      pathname: `/cotacao`,
      state: { quotation: quotation.id },
    });
  };

  const navigateToPrint = async (e) => {
    e.preventDefault();
    window.open(`/imprimir-cotacao/${quotation?.id}`);
  };

  return (
    <>
      <Container>
        <div className="description">
          <div className="icon">
            <TbReportMoney
              size={25}
              color={
                moment().endOf('day') <= moment(quotation.due_at).endOf('day') && !quotation.hasProposal
                  ? blue
                  : quotation.hasProposal
                    ? success
                    : error
              }
            />
          </div>

          <Div style={{ width: '30%' }}>
            <Title>Cliente</Title>
            <Subtitle>{quotation?.userName}</Subtitle>
          </Div>

          <Div style={{ width: '20%' }}>
            <Title>Placa</Title>
            <Subtitle>{quotation?.vehicle?.plate ? formatPlate(quotation?.vehicle?.plate) : 'Sem placa'}</Subtitle>
          </Div>

          <Div style={{ width: '20%' }}>
            <Title>Valor da cotação</Title>
            <Subtitle>{formatCurrency(quotation?.value)}</Subtitle>
          </Div>

          <Div style={{ width: '20%' }}>
            <Title>Criada em</Title>
            <Subtitle>{moment(quotation?.created_at)?.format('DD/MM/YYYY')}</Subtitle>
          </Div>
          <Div style={{ width: '20%' }}>
            <Title>Vence em</Title>
            <Subtitle>{moment(quotation?.due_at)?.format('DD/MM/YYYY')}</Subtitle>
          </Div>

          <Div style={{ width: '10%', flexDirection: 'row', justifyContent: 'center' }}>
            {moment().endOf('day') <= moment(quotation.due_at).endOf('day') && !quotation.hasProposal && (
              <>
                <Tooltip title="Imprimir cotação">
                  <IconButton onClick={navigateToPrint}>
                    <BsPrinter color="#C4C4C4" size={18} className="iconArrow" />
                  </IconButton>
                </Tooltip>

                {actionsActive && (
                  <Tooltip title="Editar cotação">
                    <IconButton onClick={navigate}>
                      <BiMessageSquareEdit color="#C4C4C4" size={19} className="iconArrow" />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </Div>
        </div>
      </Container>
    </>
  );
}

export default QuotationItem;
