/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import Cookies from 'js-cookie';
import ReactLoading from 'react-loading';
import api from 'api';
import { loadingColor, white } from 'styles/colorProvider';
import {
  Container,
  LoadingArea,
  ButtonArea,
  Printable,
  PrintStyles,
  Header,
  HrHeader,
  Footer,
  FooterRow,
  LogoIcon,
  Row,
  SectionContainer,
  SectionTitle,
  RowLabel,
  RowText,
  Column,
  SectionSmall,
  FooterText,
  ContainerBetween,
  ColumnBetween,
  RowBetween,
} from './styles';
import { formatCPF, formatCNPJ, formatPlate, formatPhone, formatZipCode, formatCurrency } from 'react-data-formatter';
import { Button } from 'components';
import { BsPrinter } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { formatDocument } from 'react-data-formatter';
import { cpf } from 'cpf-cnpj-validator';

function BackofficePrintQuotation({ location, history }) {
  const quotationId = parseInt(location.pathname.split('imprimir-cotacao/')[1]);
  const host = window.location.hostname;
  const isOceanica = host.split('.')[0] === 'oceanica';

  const [quotationData, setQuotationData] = useState({});
  const [loading, setLoading] = useState(false);
  const [valueCoverage, setValueCoverage] = useState(0);
  const [valueService, setValueService] = useState(0);
  const [hasServices, setHasServices] = useState(false);
  const [hasCoverages, setHasCoverages] = useState(false);
  const loadData = async () => {
    const quotationIssue = {
      method: 'GET',
      url: `/get-quotation-proposal-document/${quotationId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    };
    setLoading(true);
    const response = await api(quotationIssue);
    setQuotationData(response.data);
    let valueService = 0;
    let valueCoverage = 0;

    let hasServices = false;
    let hasCoverages = false;
    for (const item of response.data?.data?.coverages) {
      if (item.isService) {
        hasServices = true;
        valueService += Number(item.value);
      } else {
        hasCoverages = true;
        valueCoverage += Number(item.value);
      }
    }

    setHasServices(hasServices);
    setHasCoverages(hasCoverages);
    setLoading(false);
    setValueCoverage(valueCoverage);
    setValueService(valueService);
  };

  useEffect(() => loadData(), []);

  const isCpf = cpf.isValid(quotationData?.id_user?.document);

  return (
    <>
      <PrintStyles />
      {!loading && (
        <ButtonArea>
          <Button style={{ color: white }} height="30px" width="130px" onClick={() => window.print()}>
            <BsPrinter size="15" style={{ marginRight: 10 }} />
            Imprimir
          </Button>
        </ButtonArea>
      )}
      {loading && (
        <LoadingArea>
          <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
        </LoadingArea>
      )}
      {!loading && (
        <Container>
          <Printable>
            <Header>
              <div className="titleArea">
                <h2>
                  Olá, <b>{quotationData?.id_user?.name?.toUpperCase()}</b>
                </h2>
                <h2>
                  Para você que está sempre em movimento,
                  <br />
                  aqui estão todos os detalhes do seu Seguro Auto.
                </h2>
              </div>
              <div className="logoArea">
                <img src={quotationData?.logo || ''} />
              </div>
            </Header>

            <HrHeader />

            <section>
              <ContainerBetween>
                <ColumnBetween width="50%">
                  <Row>
                    <RowLabel>Protocolo</RowLabel>
                    <RowText>{quotationData?.protocol}</RowText>
                  </Row>

                  <Row>
                    <RowLabel>Data da cotação</RowLabel>
                    <RowText>{moment(quotationData?.created_at).format('DD/MM/YYYY [às]  HH:mm')}</RowText>
                  </Row>
                </ColumnBetween>
                <ColumnBetween width="50%">
                  <Row>
                    <RowLabel>Data de validade da cotação</RowLabel>
                    <RowText>{moment(quotationData?.due_at).format('DD/MM/YYYY')}</RowText>
                  </Row>
                </ColumnBetween>
              </ContainerBetween>

              <br />

              <SectionSmall>
                A Aceitação da proposta está sujeita a análise e aprovação do risco pela seguradora e o inicio de vigência somente
                ocorre após a aceitação do risco e do respectivo pagamento da parcela do seguro Valores descritos nesta cotação
                referem-se a mensalidade. Seguro na modalidade mensal recorrente.
              </SectionSmall>
            </section>

            <section>
              <SectionContainer>
                <SectionTitle>DADOS DO SEGURADO</SectionTitle>
              </SectionContainer>

              <Column>
                <b>Nome do segurado:</b>
                <p>{quotationData?.id_user?.name?.toUpperCase()}</p>
              </Column>

              <ContainerBetween>
                {!!quotationData?.id_user?.document && (
                  <ColumnBetween width="30%">
                    <b>CPF:</b>
                    <p>{formatCPF(quotationData?.id_user?.document)}</p>
                  </ColumnBetween>
                )}
                {!!quotationData?.id_user?.birthdate && (
                  <ColumnBetween width="45%">
                    <b>Data de nascimento:</b>
                    <p>{moment(quotationData?.id_user?.birthdate).utcOffset(0).format('DD/MM/YYYY')}</p>
                  </ColumnBetween>
                )}
              </ContainerBetween>

              <ContainerBetween>
                {!!quotationData?.id_user?.email && (
                  <ColumnBetween width="50%">
                    <b>E-mail:</b>
                    <p>{quotationData?.id_user?.email}</p>
                  </ColumnBetween>
                )}

                {!!quotationData?.id_user?.phone && (
                  <ColumnBetween width="50%">
                    <b>E-Telefone:</b>
                    <p>{formatPhone(quotationData?.id_user?.phone)}</p>
                  </ColumnBetween>
                )}
              </ContainerBetween>

              {!!quotationData?.id_address?.street && (
                <ContainerBetween>
                  <Row>
                    <RowLabel>Logradouro:</RowLabel>
                    <RowText>{quotationData?.id_address?.street}</RowText>
                  </Row>
                </ContainerBetween>
              )}

              <ContainerBetween>
                {!!quotationData?.id_address?.number && (
                  <RowBetween gap="6px" width="25%">
                    <b>Número:</b>
                    <p>{quotationData?.id_address?.number}</p>
                  </RowBetween>
                )}

                {!!quotationData?.id_address?.district && (
                  <RowBetween gap="6px" width="25%">
                    <b>Bairro:</b>
                    <p>{quotationData?.id_address?.district}</p>
                  </RowBetween>
                )}

                {!!quotationData?.id_address?.city && (
                  <RowBetween gap="6px" width="25%">
                    <b>Cidade/UF:</b>
                    <p>
                      {quotationData?.id_address?.city}
                      {quotationData?.id_address?.state ? ` / ${quotationData?.id_address?.state}` : ''}
                    </p>
                  </RowBetween>
                )}

                {!!quotationData?.id_address?.zip_code && (
                  <RowBetween gap="6px" width="25%">
                    <b>CEP:</b>
                    <p>{quotationData?.id_address?.zip_code}</p>
                  </RowBetween>
                )}
              </ContainerBetween>
            </section>
            {quotationData?.proposal && (
              <section>
                <SectionContainer>
                  <SectionTitle>CONDUTOR PRINCIPAL</SectionTitle>
                </SectionContainer>

                <Column>
                  <b>Nome do condutor:</b>
                  <p>{quotationData?.proposal?.maindriver_name || quotationData?.id_user?.name}</p>
                </Column>

                <ContainerBetween>
                  <ColumnBetween width="50%">
                    <b>CPF:</b>
                    <p>{formatCPF(quotationData?.proposal?.maindriver_document || quotationData?.id_user?.cpf)}</p>
                  </ColumnBetween>

                  {quotationData?.proposal?.maindriver_birthdate && (
                    <ColumnBetween width="50%">
                      <b>Data de nascimento:</b>
                      <p>
                        {moment(quotationData?.proposal?.maindriver_birthdate || quotationData?.id_user?.birthdate)
                          .utcOffset(0)
                          .format('DD/MM/YYYY')}
                      </p>
                    </ColumnBetween>
                  )}
                </ContainerBetween>
              </section>
            )}

            <section>
              <SectionContainer>
                <SectionTitle>DADOS DO VEÍCULO</SectionTitle>
              </SectionContainer>

              {(!!quotationData?.proposal?.maindriver_name || !!quotationData?.id_user?.name) && (
                <Row>
                  <RowLabel>Nome do condutor:</RowLabel>
                  <RowText>{quotationData?.proposal?.maindriver_name || quotationData?.id_user?.name}</RowText>
                </Row>
              )}

              {!!quotationData?.id_vehicle?.plate && (
                <Row>
                  <RowLabel>Placa:</RowLabel>
                  <RowText>{formatPlate(quotationData?.id_vehicle?.plate)}</RowText>
                </Row>
              )}

              {(!!quotationData?.id_vehicle?.manufacture_year || !!quotationData?.id_vehicle?.year_model) && (
                <Row>
                  <RowLabel>Ano fabricação/ano modelo:</RowLabel>
                  <RowText>
                    {quotationData?.id_vehicle?.manufacture_year}/{quotationData?.id_vehicle?.year_model}
                  </RowText>
                </Row>
              )}

              {!!quotationData?.id_vehicle?.chassi && (
                <Row>
                  <RowLabel>Chassi:</RowLabel>
                  <RowText>{quotationData?.id_vehicle?.chassi}</RowText>
                </Row>
              )}

              {!!quotationData?.id_vehicle?.value && (
                <Row>
                  <RowLabel>Valor*:</RowLabel>
                  <RowText>{formatCurrency(quotationData?.id_vehicle?.value)}</RowText>
                </Row>
              )}

              {!!quotationData?.id_vehicle?.TB_CONSTANT_VEHICLE_MODELS?.fipe_code && (
                <Row>
                  <RowLabel>Código Fipe:</RowLabel>
                  <RowText>{quotationData?.id_vehicle?.TB_CONSTANT_VEHICLE_MODELS?.fipe_code}</RowText>
                </Row>
              )}

              <Row>
                <RowLabel>Veículo de Leilão:</RowLabel>
                <RowText>{!!quotationData?.id_vehicle?.auction ? 'Sim' : 'Não'}</RowText>
              </Row>
              {!!quotationData?.id_vehicle?.TB_CONSTANT_VEHICLE_USES?.name && (
                <Row>
                  <RowLabel>Uso do veículo:</RowLabel>
                  <RowText>{quotationData?.id_vehicle?.TB_CONSTANT_VEHICLE_USES?.name}</RowText>
                </Row>
              )}

              <Row>
                <RowLabel>Chassis Remarcado/Veiculo Recuperado de Sinistro:</RowLabel>
                <RowText>{!!quotationData?.id_vehicle?.remarked ? 'Sim' : 'Não'}</RowText>
              </Row>
              <Row>
                <RowLabel>0km:</RowLabel>
                <RowText>{!!quotationData?.id_vehicle?.zero_km ? 'Sim' : 'Não'}</RowText>
              </Row>
              <div class="page-break" />
              <Row>
                <RowLabel>Possui GNV:</RowLabel>
                <RowText>{!!quotationData?.id_vehicle?.gnv ? 'Sim' : 'Não'}</RowText>
              </Row>
              {!!quotationData?.id_address?.state && (
                <Row>
                  <RowLabel>Região de Circulação:</RowLabel>
                  <RowText>
                    {quotationData?.id_address?.city} / {quotationData?.id_address?.state}
                  </RowText>
                </Row>
              )}

              <SectionSmall>* Valor da Tabela Fipe do veículo na data da contratação</SectionSmall>
            </section>

            <section>
              <SectionContainer>
                <SectionTitle>DADOS DA COTAÇÃO</SectionTitle>
              </SectionContainer>

              <Row>
                <RowLabel>Modalidade de Contratação:</RowLabel>
                <RowText> VMR - Valor de Mercado Referenciado</RowText>
              </Row>

              <Row>
                <RowLabel>Fator de Ajuste LMI: </RowLabel>
                <RowText>{quotationData?.data?.adjustment || quotationData?.data?.adjustment_factor || 100}%*</RowText>
              </Row>

              <Column>
                <b>LIMITE MÁXIMO DE INDENIZAÇÃO (LMI):</b>
                <p>
                  Valor máximo a ser pago pela SEGURADORA, contratada para cada cobertura, escolhida pelo SEGURADO(A) e expresso
                  na proposta.
                </p>
              </Column>

              <Row>
                <RowLabel>PEÇAS E REPAROS:</RowLabel>
                <RowText>
                  UTILIZAÇÃO DE PEÇAS (NOVAS, USADAS, ORIGINAIS OU NÃO), desde que mantenham a especificação técnica do
                  fabricante.
                </RowText>
              </Row>

              {hasCoverages && (
                <>
                  <SectionContainer>
                    <SectionTitle>COBERTURAS</SectionTitle>
                  </SectionContainer>

                  <table>
                    <thead>
                      <tr>
                        <td width={300}>Cobertura</td>
                        <td width={200}>LMI **</td>
                        <td width={150}>Prêmio</td>
                        <td width={250}>Franquias</td>
                      </tr>
                    </thead>
                    <tbody>
                      {quotationData?.data?.coverages
                        ? quotationData?.data?.coverages
                            .filter((item) => item.isService === false)
                            .map((product) => (
                              <tr>
                                <td>{product?.name}</td>
                                <td>{formatCurrency(product?.lmi || '--')}</td>
                                <td>{formatCurrency(product?.value || '--')}</td>
                                <td>{product?.deductible || '--'}</td>
                              </tr>
                            ))
                        : ''}
                    </tbody>
                  </table>
                </>
              )}
              {hasServices && (
                <>
                  <SectionContainer>
                    <SectionTitle>SERVIÇOS</SectionTitle>
                  </SectionContainer>
                  <table>
                    <thead>
                      <tr>
                        <td width={300}>Serviço</td>
                        <td width={200}>LMI **</td>
                        <td width={150}>Valor</td>
                        <td width={250}>Franquias</td>
                      </tr>
                    </thead>
                    <tbody>
                      {quotationData?.data?.coverages
                        ? quotationData?.data?.coverages
                            .filter((item) => item.isService === true)
                            .map((product) => (
                              <tr>
                                <td>{product?.name}</td>
                                <td>{formatCurrency(product?.lmi || '--')}</td>
                                <td>{formatCurrency(product?.value || '--')}</td>
                                <td>{product?.deductible || '--'}</td>
                              </tr>
                            ))
                        : ''}
                    </tbody>
                  </table>
                </>
              )}

              <SectionSmall>
                Em caso de indenização integral ou roubo e furto total, será aplicada a franquia regressiva estabelecida nas
                condições gerais da apolice.
              </SectionSmall>

              <br />

              <SectionSmall>* Se selecionada a opção de leilão, o fator de ajuste deverá ser de 70%</SectionSmall>
              <SectionSmall>** Limite máximo de indenização</SectionSmall>
            </section>

            <section>
              <h1>Demostrativo do prêmio </h1>
              <h4 className="valueInfo">Prêmio Líquido Coberturas: {formatCurrency(valueCoverage * 0.931272)}</h4>
              <h4 className="valueInfo">IOF: {formatCurrency(valueCoverage - valueCoverage * 0.931272)}</h4>
              {hasCoverages && <h4 className="valueInfo">Prêmio Total Coberturas: {formatCurrency(valueCoverage)}</h4>}
              {hasServices && <h4 className="valueInfo">Custo Serviços: {formatCurrency(valueService)}</h4>}
              <h4 className="valueInfo">Valor Total Seguro: {formatCurrency(valueCoverage + valueService)}</h4>
            </section>

            <div class="page-break" />

            <section style={{ borderBottom: 'none' }}>
              <SectionContainer>
                <SectionTitle>RESUMO DAS COBERTURAS</SectionTitle>
              </SectionContainer>

              <b>Canais de Atendimento</b>
              <h4>SAC: {quotationData?.data?.provider_assistance?.phone_sac}</h4>
              <h4>Email: {quotationData?.data?.provider_assistance?.email}</h4>
              <h4>Assistência 24h: {quotationData?.data?.provider_assistance?.phone_support}</h4>

              <br />

              <h2>A íntegra das condições gerais deste produto, podem ser consultadas no portal www.splitrisk.com.br</h2>

              <h2>
                É fundamental que o segurado tenha conhecimento das cláusulas: 'Cláusula de riscos excluídos', ‘Perdas de
                Direitos', 'Obrigações do Segurado' e 'Cláusula de participação proporcional ou franquia', constantes nas
                condições gerais.
              </h2>

              {quotationData?.data?.coverages
                ? quotationData?.data?.coverages.map((product, index) => (
                    <>
                      {index === 3 && <div class="page-break" />}
                      <span className="generalConditionsTitle">{product?.name}</span>{' '}
                      <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>{product?.description}</p>
                    </>
                  ))
                : ''}

              <>
                <span className="generalConditionsTitle">Valor determinado:</span>{' '}
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  É a modalidade de seguro, que garante ao Segurado, no caso de indenização integral, roubo ou furto do veículo, o
                  pagamento de quantia fixa, em moeda corrente nacional, conforme o LMI-Limite Máximo de Indenização e Fator de
                  Ajuste estabelecidos no ato da contratação e independentemente do valor de mercado do veículo quando da
                  ocorrência do sinistro.
                </p>
              </>

              <>
                <span className="generalConditionsTitle">Fator de ajuste:</span>{' '}
                <p style={{ textAlign: 'justify', marginBottom: 15, fontSize: 12 }}>
                  É o percentual estabelecido pela SEGURADORA ou seu corretor de seguros no ato da contratação do seguro, que será
                  aplicado sobre o valor que constar no LMI-Limite Máximo de Indenização de cotação para o veículo, para a
                  estipulação do valor da indenização integral do veículo segurado. A aplicação do fator de ajuste pode resultar
                  em valor inferior àquele valor do LMI-Limite Máximo de Indenização estabelecido na proposta, de acordo com as
                  características do veículo e seu estado de conservação.
                </p>
              </>
            </section>

            <section>
              <SectionSmall style={{ marginTop: 30 }}>
                O Seguro Auto é um produto da Split Risk Seguradora regularizada na SUSEP, pelo projeto Sandbox, no processo
                15414.618377/2020-87: https://www.gov.br/susep/pt-br/assuntos/sandbox-regulatorio/sandbox-regulatorio-1a-edicao
              </SectionSmall>
            </section>
          </Printable>
        </Container>
      )}

      <Footer>
        <FooterRow>
          <FooterText>
            <p>
              Proposta emitida por: <b>{quotationData?.partner?.social_reason}</b> | CNPJ: {quotationData?.partner?.cnpj}{' '}
            </p>
          </FooterText>
          <LogoIcon>
            <img src={quotationData?.logo || ''} alt="Logo parceiro" />
          </LogoIcon>
        </FooterRow>
      </Footer>
    </>
  );
}

export default BackofficePrintQuotation;
