import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';

import { Container, MenuItem, MenuButton, MenuItemCollapsable, SubItem, SubItems } from './styles';

import { BsShieldCheck, BsChatSquareText, BsShieldX, BsCashCoin } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';
import { HiMenu, HiOutlineKey } from 'react-icons/hi';
import { RiTeamLine } from 'react-icons/ri';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { AiOutlineRight } from 'react-icons/ai';

function SideMenu() {
  const location = useLocation();
  const path = location.pathname;

  const history = useHistory();
  const [collapsed, setCollapsed] = useState(true);
  const [collapsedItem, setCollapsedItem] = useState(true);

  const toggleCollapsed = () => {
    setCollapsedItem(!collapsedItem);
  };

  const permissions = JSON.parse(Cookies.get('splitriskwl-backoffice-permissions'));

  const hasPermission = (menu) => {
    const menuIndex = permissions.findIndex((i) => i.feature === menu);
    if (menuIndex === -1) return;
    return permissions[menuIndex].hasPermission;
  };

  const menuData = [
    {
      name: 'Propostas',
      url: '/backoffice/propostas',
      icon: <IoDocumentTextOutline />,
      requiredPermission: 'menu-propostas',
    },
    {
      name: 'Apólices',
      url: '/backoffice/apolices',
      icon: <BsShieldCheck />,
      requiredPermission: 'menu-apolices',
    },
    {
      name: 'Cobranças',
      url: '/backoffice/cobrancas',
      icon: <BsCashCoin />,
      requiredPermission: 'menu-apolices',
    },
    {
      name: 'Reclamações',
      url: '/backoffice/reclamacoes',
      icon: <BsChatSquareText />,
      requiredPermission: 'menu-reclamacoes',
    },
    {
      name: 'Usuários',
      url: '/backoffice/usuarios',
      icon: <FiUsers />,
      requiredPermission: 'menu-usuarios',
    },
    {
      name: 'Equipes',
      url: '/backoffice/equipes',
      icon: <RiTeamLine size={18} />,
    },
    {
      name: 'Key Account',
      url: '/backoffice/key-account',
      icon: <HiOutlineKey size={18} />,
    },

    {
      name: 'Cancelamentos',
      url: '/backoffice/cancelamentos',
      icon: <BsShieldX />,
      requiredPermission: 'menu-cancelamentos',
    },

    {
      name: 'Produtos',
      url: '/backoffice/produtos',
      icon: <BsShieldX />,
      requiredPermission: 'menu-produtos-corporativo',
      subItems: [
        {
          name: 'Travas de comissionamento',
          url: '/backoffice/produtos/parceiros-comissao',
        },
        {
          name: 'Comissionamento de assessorias',
          url: '/backoffice/produtos/comissionamento-assesorias',
          requiredPermission: 'menu-comissionamento-assessorias',
        },
      ],
    },
  ];

  return (
    <Container collapsed={collapsed}>
      <MenuButton collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
        <HiMenu />
      </MenuButton>

      {menuData?.map((menu) => (
        <>
          {menu?.subItems && menu?.requiredPermission && hasPermission(menu?.requiredPermission) ? (
            // Collapsable item with subitems
            <>
              <MenuItemCollapsable
                collapsedSidebar={collapsed}
                className={path.includes(menu?.url) && 'select'}
                collapsedItem={collapsedItem}
                onClick={toggleCollapsed}
              >
                {menu?.icon}
                <span className="menuText">{menu.name}</span>
                <AiOutlineRight className="arrow" />
              </MenuItemCollapsable>
              <SubItems collapsedSidebar={collapsed} collapsedItem={collapsedItem}>
                {collapsed ||
                  menu.subItems
                    .filter((subItem) => !subItem.requiredPermission || hasPermission(subItem.requiredPermission))
                    .map((subItem) => (
                      <SubItem collapsed={collapsedItem} onClick={() => history.push(subItem.url)}>
                        <p className="menuText">{subItem.name}</p>
                      </SubItem>
                    ))}
              </SubItems>
            </>
          ) : menu?.requiredPermission ? (
            hasPermission(menu?.requiredPermission) && (
              <MenuItem
                collapsed={collapsed}
                className={path.includes(menu?.url) && 'select'}
                onClick={() => history.push(menu?.url)}
              >
                {menu?.icon}
                <p className="menuText">{menu?.name}</p>
              </MenuItem>
            )
          ) : (
            <MenuItem
              collapsed={collapsed}
              className={path.includes(menu?.url) && 'select'}
              onClick={() => history.push(menu?.url)}
            >
              {menu?.icon}
              <p className="menuText">{menu?.name}</p>
            </MenuItem>
          )}
        </>
      ))}
    </Container>
  );
}

export default SideMenu;
