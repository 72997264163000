import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import Avatar from '@mui/material/Avatar';
import Cookies from 'js-cookie';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import api from 'api';

import { formatCPF, formatPhone } from 'react-data-formatter';
import { Header, Select, Button, ConfirmDialog } from 'components';
import {
  Container,
  ContentArea,
  Title,
  ItemPolicy,
  ItemPolicyAvatar,
  PolicyInformationArea,
  Subtitle,
  Form,
  LoadingArea,
  Text,
  EnviromentSection,
} from './styles';
import { toast } from 'react-toastify';
import { loadingColor } from 'styles/colorProvider';
import corporateSettings from 'corporateSettings.json';
import { MdChevronLeft } from 'react-icons/md';
import { ChangePhoto, Dialog } from 'components';
import { jwtDecode } from 'jwt-decode';
import { hasPermission, Permissions } from 'utils/permissions';

const isHml = window.location.host.includes('hml');

function Profile({ history }) {
  const loggedUser = jwtDecode(Cookies.get('splitriskwl-authtoken'));
  const currentPartnerData = corporateSettings.find((i) => i.id_partner === loggedUser?.id_partner);
  const currentPartnerId = currentPartnerData?.id_partner;
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [userData, setUserData] = useState({});
  const [openPhoto, setOpenPhoto] = useState(false);
  const [selectedPartnerId, setSelectedPartnerId] = useState(currentPartnerId);
  const [showConfirmEnviromentModal, setShowConfirmEnviromentModal] = useState(false);
  const [allowedPartnersDestinations, setAllowedPartnersDestinations] = useState([]);

  const canChangeEnviroment = hasPermission(Permissions.CHANGE_PARTNER_ENVIROMENT);

  const getUser = async () => {
    const userInfo = {
      method: 'GET',
      url: `/me`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Cookies.get('splitriskwl-authtoken'),
      },
      json: true,
    };
    setLoading(true);
    const response = await api(userInfo);

    setUserData(response.data);
    setLoading(false);
  };

  const handleLogout = async () => {
    try {
      Cookies.remove('splitriskwl-acceptCookies');
      await api({
        method: 'POST',
        url: `/logout`,
        headers: {
          'Content-Type': 'application/json',
          authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {},
        json: true,
      });
      Cookies.remove('splitriskwl-authtoken');
      const newPartnerSubdomain = corporateSettings.find((i) => i.id_partner === selectedPartnerId)?.subdomain;
      const newPartnerUrl = isHml
        ? `https://${newPartnerSubdomain}.hml.splitrisk.com.br`
        : `https://${newPartnerSubdomain}.splitrisk.com.br`;

      window.location.href = newPartnerUrl;
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateUserPartnerEnviroment = async () => {
    setLoadingUpdate(true);
    try {
      await api({
        method: 'PATCH',
        url: `/user-partner/${loggedUser.id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: { id_partner: selectedPartnerId },
        json: true,
      });
      setLoadingUpdate(false);
      setShowConfirmEnviromentModal(false);
      toast.success('Ambiente alterado com sucesso!', {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
      handleLogout();
    } catch (error) {
      setLoadingUpdate(false);
      toast.error(error?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        theme: 'colored',
      });
    }
  };

  const handleGetAllowedEnviromentDestinations = async () => {
    try {
      const res = await api({
        method: 'GET',
        url: `/user-partner-destinations`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        json: true,
      });
      setAllowedPartnersDestinations([{ id: currentPartnerId, name: currentPartnerData?.name }, ...res.data]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
    if (canChangeEnviroment) {
      handleGetAllowedEnviromentDestinations();
    }
  }, []);

  const renderConfirmDialogChangePartnerEnvironment = () => (
    <ConfirmDialog
      open={showConfirmEnviromentModal}
      onCancel={() => setShowConfirmEnviromentModal(false)}
      content="Tem certeza que deseja alterar o ambiente deste usuário? Todas as informações serão migradas para o novo ambiente."
      title="Alterar ambiente"
      onConfirm={handleUpdateUserPartnerEnviroment}
      loading={loadingUpdate}
    />
  );

  return (
    <>
      {renderConfirmDialogChangePartnerEnvironment()}
      <Header />
      <Container>
        <ContentArea>
          {loading && (
            <LoadingArea>
              <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
            </LoadingArea>
          )}
          {!loading && (
            <>
              <Title>
                <IconButton size="small" style={{ marginRight: 10 }} onClick={() => history.push('/minhas-apolices')}>
                  <MdChevronLeft size={24} />
                </IconButton>
                Meu perfil
              </Title>

              <PolicyInformationArea>
                <ItemPolicy>
                  <Subtitle>Dados do usuário</Subtitle>
                  <Text>
                    Nome completo: <span>{userData?.name}</span>
                  </Text>
                  <Text>
                    CPF: <span>{formatCPF(userData?.cpf)}</span>
                  </Text>
                  <Text>
                    Data de nascimento: <span>{moment(userData?.birthdate).utcOffset(0).format('DD/MM/YYYY')}</span>
                  </Text>

                  {userData?.gender === null && (
                    <Text>
                      Sexo: <span>Não registrado</span>
                    </Text>
                  )}
                  {userData?.gender === 1 && (
                    <Text>
                      Sexo: <span>Masculino</span>
                    </Text>
                  )}
                  {userData?.gender === 2 && (
                    <Text>
                      Sexo: <span>Feminino</span>
                    </Text>
                  )}

                  <Text>
                    Telefone: <span>{formatPhone(userData?.phone)}</span>
                  </Text>
                  <Text>
                    E-mail: <span>{userData?.email}</span>
                  </Text>
                  <Text>
                    Empresa: <span>{userData?.company}</span>
                  </Text>
                </ItemPolicy>
                <ItemPolicyAvatar>
                  <Avatar
                    onClick={() => setOpenPhoto(true)}
                    src={userData?.signedUrl}
                    alt={userData?.name}
                    image={userData?.signedUrl}
                    sx={{ width: 150, height: 150 }}
                    style={{ marginRight: 9, cursor: 'pointer' }}
                  />
                  <p style={{ fontSize: 14, cursor: 'pointer' }} onClick={() => setOpenPhoto(true)}>
                    Alterar foto
                  </p>
                </ItemPolicyAvatar>
              </PolicyInformationArea>
              <Dialog open={openPhoto} onClose={() => setOpenPhoto()} title="Foto de perfil">
                <ChangePhoto userData={userData} onChange={() => getUser()} handleCloseModal={() => setOpenPhoto()} />
              </Dialog>
              {canChangeEnviroment && (
                <EnviromentSection>
                  <Subtitle>Ambiente</Subtitle>
                  <Form>
                    <div class="grid-item">
                      <Select
                        label="Parceiro"
                        height="35px"
                        value={selectedPartnerId}
                        onChange={(e) => {
                          setUserData({ ...userData, id_partner: e.target.value });
                          setSelectedPartnerId(parseInt(e.target.value));
                        }}
                      >
                        {allowedPartnersDestinations?.map((partner) => (
                          <option value={partner?.id}>{partner?.name}</option>
                        ))}
                      </Select>
                    </div>
                  </Form>

                  <Button
                    style={{ marginLeft: 0 }}
                    height="35px"
                    disabled={loadingUpdate || selectedPartnerId === currentPartnerId}
                    onClick={() => setShowConfirmEnviromentModal(true)}
                  >
                    Alterar ambiente
                  </Button>
                </EnviromentSection>
              )}
            </>
          )}
        </ContentArea>
      </Container>
    </>
  );
}

export default Profile;
