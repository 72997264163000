import { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import api from 'api';
import { Button, ConfirmDialog, Dialog, Header, Search, SideMenu } from 'components/index';
import { loadingColor, primary } from 'styles/colorProvider';
import ReactLoading from 'react-loading';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import {
  ButtonTable,
  Container,
  ContentArea,
  CountArea,
  DataArea,
  DialogBody,
  Div,
  LoadingArea,
  ModalButton,
  ModalError,
  Title,
} from './styles';
import InfiniteScroll from 'react-infinite-scroller';
import { SliderContent } from '../Partners/styles';

function BackOfficeProductsConsultancyCommission({ history }) {
  const scrollParent = useRef();

  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [consultancycommissionsList, setConsultancycommissionsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [search, setSearch] = useState();
  const [searchConsultancy, setSearchConsultancy] = useState();
  const [partnerConsultancies, setPartnerConsultancies] = useState([]);
  const [consultancyCommissionInfo, setConsultancyCommissionInfo] = useState({});
  const [userPartnerInfo, setUserPartnerInfo] = useState({
    loading: null,
    maxLoading: null,
  });

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleLoadMore = async () => {
    const page = currentPage + 1;
    if (hasNextPage && !loadingMore) {
      setLoadingMore(true);

      const resp = await api({
        method: 'GET',
        url: `/consultancy-commission`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          page: page,
          take: 15,
          search,
        },
        json: true,
      });

      const newConsultancycommissionsList = consultancycommissionsList.concat(resp?.data.consultancyCommissions);
      setConsultancycommissionsList(newConsultancycommissionsList);
      setHasNextPage(resp?.data?.hasNextPage);
      setCurrentPage(page);
      setLoadingMore(false);
    }
  };

  const handleGetPartnerInfo = async () => {
    try {
      setLoading(true);

      const options = {
        method: 'GET',
        url: `/list-backoffice-partner`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        json: true,
      };

      const { data } = await api(options);

      setUserPartnerInfo({
        loading: data.loading_partner,
        maxLoading: data.max_loading_partner,
        discount: data.discount_loading_partner,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetConsutancyCommissions = async () => {
    setLoading(true);
    try {
      const resp = await api({
        method: 'GET',
        url: `/consultancy-commission`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          page: currentPage,
          take: 15,
          search,
        },
        json: true,
      });
      setConsultancycommissionsList(resp?.data.consultancyCommissions);
      setTotalCount(resp?.data?.totalCount);
      setHasNextPage(resp?.data?.hasNextPage);
    } catch (error) {
      history.push('/nao-autorizado');
      console.log(error);
    }
    setLoading(false);
  };

  const handleGetPartnerConsultancies = async (searchValue) => {
    setLoading(true);
    try {
      const resp = await api({
        method: 'GET',
        url: `/list-key-account`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        params: {
          search: searchValue || search,
        },
        json: true,
      });
      setPartnerConsultancies(resp?.data?.response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddConsultancyCommission = async () => {
    setLoadingButton(true);
    try {
      await api({
        method: 'POST',
        url: `/consultancy-commission`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: {
          id_key_account: consultancyCommissionInfo?.id_key_account,
          percent: consultancyCommissionInfo?.percent,
        },
        json: true,
      });
      handleGetConsutancyCommissions();
      toast.success('Comissão de assessoria adicionada com sucesso', {
        position: 'top-right',
        theme: 'colored',
      });
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Não foi possivel adicionar comissão de assessoria', {
        position: 'top-right',
        theme: 'colored',
      });
      console.log(error);
    } finally {
      setLoadingButton(false);
      setShowAddModal(false);
      setConsultancyCommissionInfo({});
      setSearchConsultancy(null);
      setPartnerConsultancies([]);
    }
  };

  const handleEditConsultancyCommission = async () => {
    setLoadingButton(true);
    try {
      await api({
        method: 'PUT',
        url: `/consultancy-commission/${consultancyCommissionInfo?.id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        data: { percent: consultancyCommissionInfo?.percent },
        json: true,
      });
      handleGetConsutancyCommissions();
      toast.success('Comissão de assessoria editada com sucesso', {
        position: 'top-right',
        theme: 'colored',
      });
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Não foi possivel editar comissão de assessoria', {
        position: 'top-right',
        theme: 'colored',
      });
      console.log(error);
    } finally {
      setLoadingButton(false);
      setShowEditModal(false);
      setConsultancyCommissionInfo({});
    }
  };

  const handleDeleteConsultancyCommission = async () => {
    setLoadingButton(true);
    try {
      await api({
        method: 'DELETE',
        url: `/consultancy-commission/${consultancyCommissionInfo?.id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: Cookies.get('splitriskwl-authtoken'),
        },
        json: true,
      });
      handleGetConsutancyCommissions();
      toast.success('Comissão de assessoria deletada com sucesso', {
        position: 'top-right',
        theme: 'colored',
      });
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Não foi possivel deletar comissão de assessoria', {
        position: 'top-right',
        theme: 'colored',
      });
      console.log(error);
    } finally {
      setLoadingButton(false);
      setShowDeleteModal(false);
      setConsultancyCommissionInfo({});
    }
  };

  useEffect(() => {
    handleGetConsutancyCommissions();
    handleGetPartnerInfo();
  }, []);

  const dataColumns = [
    {
      name: 'ID',
      selector: (row) => row?.id,
      width: '25%',
      sortable: true,
    },
    {
      name: 'Assessoria',
      selector: (row) => row?.name,
      width: '30%',
      sortable: true,
    },
    {
      name: 'Comissão',
      selector: (row) => row?.percent,
      width: '25%',
      sortable: true,
    },
    {
      name: 'Editar',
      selector: (row) => (
        <ButtonTable
          onClick={() => {
            setConsultancyCommissionInfo({
              id: row?.id,
              name: row?.name,
              percent: row?.percent,
            });
            setShowEditModal(true);
          }}
        >
          <BiPencil size={16} />
        </ButtonTable>
      ),
      width: '10%',
      sortable: false,
    },
    {
      name: 'Deletar',
      selector: (row) => (
        <ButtonTable
          onClick={() => {
            setConsultancyCommissionInfo({
              id: row?.id,
              name: row?.name,
              percent: row?.percent,
            });
            setShowDeleteModal(true);
          }}
        >
          <BiTrash size={16} />
        </ButtonTable>
      ),
      width: '10%',
      sortable: false,
    },
  ];

  const renderAddConsultancyCommission = () => (
    <Dialog
      open={showAddModal}
      onClose={() => {
        setShowAddModal(false);
        setConsultancyCommissionInfo({
          id: undefined,
          percent: '',
        });
      }}
      width="100%"
      title="Adicionar comissão de assessoria"
    >
      <DialogBody>
        <div class="input-group">
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <div className="search-area">
              <p style={{ fontSize: 12 }}>Assessoria:</p>
              <Search
                style={{ margin: 0 }}
                help="Buscar pelo nome da assessoria"
                value={searchConsultancy}
                onChange={(e) => setSearchConsultancy(e.target.value)}
                onClear={handleGetPartnerConsultancies}
                onConfirm={() => handleGetPartnerConsultancies(searchConsultancy)}
              />
            </div>
            <div className="result-area">
              <div>
                {partnerConsultancies?.map((partnerConsultancy) => (
                  <div
                    key={partnerConsultancy.id}
                    className={
                      consultancyCommissionInfo?.id_key_account === partnerConsultancy?.id
                        ? 'result-item result-item-selected'
                        : 'result-item'
                    }
                    onClick={() =>
                      setConsultancyCommissionInfo({
                        ...consultancyCommissionInfo,
                        id_key_account: partnerConsultancy?.id,
                      })
                    }
                  >
                    <p>{partnerConsultancy?.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <p
          style={{
            fontSize: '12px',
          }}
        >
          Comissão
        </p>
        <div
          style={{
            display: 'flex',
            margin: '0 auto',
            width: '95%',
          }}
        >
          <SliderContent
            track={false}
            aria-labelledby="track-false-slider"
            valueLabelDisplay="auto"
            valueLabelFormat={(e) => {
              return `${Math.round(e)}%`;
            }}
            getAriaValueText={(value) => `${value}`}
            defaultValue={consultancyCommissionInfo.percent || 0}
            value={consultancyCommissionInfo.percent}
            onChange={(event) => {
              setConsultancyCommissionInfo({
                ...consultancyCommissionInfo,
                percent: event.target.value,
              });
            }}
            onChangeCommitted={() => {}}
            min={0}
            max={userPartnerInfo.maxLoading - userPartnerInfo.loading}
            step={1}
            marks={[
              {
                value: 0,
                label: `0%`,
              },
              {
                value: userPartnerInfo.maxLoading - userPartnerInfo.loading,
                label: `${userPartnerInfo.maxLoading - userPartnerInfo.loading}%`,
              },
            ]}
          />
        </div>

        <ModalError showError={consultancyCommissionInfo.percent > userPartnerInfo.maxLoading - userPartnerInfo.loading}>
          A comissão não pode ser maior que o carregamento + comercialização
        </ModalError>

        <ModalButton>
          {loading && <ReactLoading color={loadingColor} height={40} width={40} type="spin" />}

          <Button
            height="35px"
            onClick={handleAddConsultancyCommission}
            disabled={loading || !consultancyCommissionInfo.percent || !consultancyCommissionInfo.id_key_account}
          >
            Adicionar
          </Button>
        </ModalButton>
      </DialogBody>
    </Dialog>
  );

  const renderEditConsultancyCommission = () => (
    <Dialog
      open={showEditModal}
      onClose={() => {
        setShowEditModal(false);
        setConsultancyCommissionInfo({});
      }}
      width="100%"
      title="Editar comissão de assessoria"
    >
      <DialogBody>
        <div class="input-group">
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <div className="search-area">
              <p style={{ fontSize: 12 }}>Assessoria:</p>
            </div>
            <div className="result-area">
              <div>
                <div className="result-item result-item-selected">
                  <p>{consultancyCommissionInfo?.name}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p
          style={{
            fontSize: '12px',
          }}
        >
          Comissão
        </p>
        <div
          style={{
            display: 'flex',
            margin: '0 auto',
            width: '95%',
          }}
        >
          <SliderContent
            track={false}
            aria-labelledby="track-false-slider"
            valueLabelDisplay="auto"
            valueLabelFormat={(e) => {
              return `${Math.round(e)}%`;
            }}
            getAriaValueText={(value) => `${value}`}
            defaultValue={consultancyCommissionInfo.percent || 0}
            value={consultancyCommissionInfo.percent}
            onChange={(event) => {
              setConsultancyCommissionInfo({
                ...consultancyCommissionInfo,
                percent: event.target.value,
              });
            }}
            onChangeCommitted={() => {}}
            min={0}
            max={userPartnerInfo.maxLoading - userPartnerInfo.loading}
            step={1}
            marks={[
              {
                value: 0,
                label: `0%`,
              },
              {
                value: userPartnerInfo.maxLoading - userPartnerInfo.loading,
                label: `${userPartnerInfo.maxLoading - userPartnerInfo.loading}%`,
              },
            ]}
          />
        </div>

        <ModalError showError={consultancyCommissionInfo.percent > userPartnerInfo.maxLoading - userPartnerInfo.loading}>
          A comissão não pode ser maior que o carregamento + comercialização
        </ModalError>

        <ModalButton>
          {loading && <ReactLoading color={loadingColor} height={40} width={40} type="spin" />}

          <Button
            height="35px"
            onClick={handleEditConsultancyCommission}
            disabled={loading || !consultancyCommissionInfo.percent}
          >
            Editar
          </Button>
        </ModalButton>
      </DialogBody>
    </Dialog>
  );

  const renderDeleteConsultancyCommission = () => (
    <ConfirmDialog
      open={showDeleteModal}
      onCancel={() => {
        setShowDeleteModal(false);
        setConsultancyCommissionInfo({
          ...consultancyCommissionInfo,
          id: undefined,
        });
      }}
      content="Tem certeza de que deseja excluir essa comissão?"
      title="Excluir comissão?"
      onConfirm={handleDeleteConsultancyCommission}
      loading={loadingButton}
    />
  );

  return (
    <>
      <Header />
      <Container>
        {renderAddConsultancyCommission()}
        {renderEditConsultancyCommission()}
        {renderDeleteConsultancyCommission()}
        <SideMenu />
        <ContentArea>
          <Div>
            <Title>COMISSIONAMENTO DE ASSESSORIAS</Title>
            <div className="containerTitle">
              <Button title="Adicionar" height="30px" onClick={() => setShowAddModal(true)} disabled={loading}>
                Adicionar
              </Button>

              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClear={() => {
                  setSearch('');
                  handleGetConsutancyCommissions();
                }}
                onConfirm={handleGetConsutancyCommissions}
              />
            </div>
          </Div>

          <DataArea ref={scrollParent}>
            <InfiniteScroll
              loadMore={handleLoadMore}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollParent?.current}
              initialLoad={false}
              style={{ width: '100%', display: 'flex' }}
              threshold={0}
            >
              <DataTable
                persistTableHead
                responsive
                columns={dataColumns}
                data={consultancycommissionsList}
                highlightOnHover
                pointerOnHover
                fixedHeader
                fixedHeaderScrollHeight="100%"
                progressPending={loading}
                progressComponent={
                  <LoadingArea>
                    <ReactLoading color={loadingColor} height={40} width={40} type="spin" />
                  </LoadingArea>
                }
                noDataComponent={<small style={{ marginTop: 20 }}>Nenhum registro encontrado</small>}
              />
            </InfiniteScroll>
          </DataArea>
          <CountArea>
            {loadingMore && <ReactLoading color={loadingColor} height={20} width={20} type="spin" />}

            {!loadingMore && !loading && (
              <p style={{ color: primary }}>
                Mostrando {consultancycommissionsList?.length} de {totalCount}
              </p>
            )}
          </CountArea>
        </ContentArea>
      </Container>
    </>
  );
}

export default BackOfficeProductsConsultancyCommission;
